body {
  background-color: #6c8eb4;
  min-height: 100vh;
  margin: 0;
  display: flex;
  justify-content: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto',
    monospace;
}

.container {
  margin: 0 auto;
  padding: 20px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: bold;
}

p {
  font-size: 20px;
  color: #f0f8ff;
}

.nome-cognome {
  font-size: 20px;
  color: #f0f8ff;
  display: flex;
  justify-content: center;
}

.biografia {
  font-size: 16px;
  font-weight: lighter;
  color: #f0f8ff;
  display: flex;
  justify-content: center;
  margin: 10px;
  margin-bottom: 0px;
}

.images {
  display: flex;
  justify-content: center;
}

.bio {
  margin: 0;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1px;
}